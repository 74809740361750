import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Redirect } from '../types/redirect.type';
import { ApiUrl } from '../../constants/apiUrl.const';
import { GrammaticalForm } from '../types/grammaticalForm.enum';
import { Language } from '../types/language.enum';

@Injectable({
  providedIn: 'root'
})
export class RedirectRestService {
  private readonly httpClient = inject(HttpClient);

  public getRedirect(hash: string): Observable<Redirect> {
    return this.httpClient.get<Redirect>(`${ApiUrl}/redirect/${hash}`);
  }

  public getReview(
    length: string,
    hash: string,
    answers: { questionId: string; values: string[] }[],
    grammaticalForm: GrammaticalForm,
    language: Language
  ): Observable<string> {
    return this.httpClient.post<string>(`${ApiUrl}/review`, { hash, answers, length, grammaticalForm, language });
  }

  public logRedirectOpened(deviceId: string, hash: string): Observable<void> {
    return this.httpClient.post<void>(`${ApiUrl}/event/redirectOpened`, { deviceId, hash });
  }
}
