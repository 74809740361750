import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Redirect } from '../../redirect/types/redirect.type';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'client-logo',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <img
      *ngIf="redirect()?.logoUrl as logo else Title"
      [src]="logo"
      [alt]="redirect()?.name"
      data-sentry-mask
      class="max-w-[100%] max-h-[100px] mb-3"
    />
    <ng-template #Title>
      <h3 class="text-2xl" data-sentry-mask>{{ redirect()?.name }}</h3>
    </ng-template>
  `,
  host: {
    class: 'flex justify-center'
  },
  imports: [
    NgIf
  ],
  standalone: true
})
export class LogoOrTitleComponent {
  redirect = input<Redirect>();
}
